import './styles.scss';

export const IndicatorListItem = ({data, title, onDelete, onUpdate}) =>{
  const formattedDate = new Date(data.created_at).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return(
    title ?
    <div className="indicator-list-item title">
      <div className="origin">Origem</div>
      <div className="indicator">Nome indicador</div>
      <div className="indicator-type">Tipo indicador</div>
      <div className="user">Nome</div>
      <div className="user-type">Tipo</div>
      <div className="user-plan">Plano</div>
      <div className="date">Data</div>
      {localStorage.getItem('level') === '1' && <div className="edit">Editar</div>}
      {localStorage.getItem('level') === '1' && <div className="edit">Excluir</div>}
    </div>
    :
    <div className="indicator-list-item">
      <div className="origin">{data.origin}</div>
      <div className="indicator">{data.affiliate?.name}</div>
      <div className="indicator-type">
        {data.affiliate_type === 'C' && <span>Corretor</span>}
        {data.affiliate_type === 'I' && <span>Imobiliária</span>}
        {data.affiliate_type === 'T' && <span>Construtora</span>}
        {data.affiliate_type === 'A' && <span>Admin</span>}
        {data.affiliate_type === 'P' && <span>Proprietário</span>}
      </div>
      <div className="user">{data.user?.name}</div>
      <div className="user-type">
        {data.user?.type === 'C' && <span>Corretor</span>}
        {data.user?.type === 'I' && <span>Imobiliária</span>}
        {data.user?.type === 'T' && <span>Construtora</span>}
        {data.user?.type === 'A' && <span>Admin</span>}
        {data.user?.type === 'P' && <span>Proprietário</span>}
      </div>
      <div className="user-plan">
        {data.user?.premium === 0 && <span className="free">Free</span>}
        {data.user?.premium === 2 && <span className="start">Start</span>}
        {data.user?.premium === 1 && <span className="premium">Premium</span>}
      </div>
      <div className="date">{formattedDate}</div>
      {localStorage.getItem('level') === '1' && <div className="edit"><i className="ri-edit-line" onClick={() => onUpdate(data.id)}></i></div>}
      {localStorage.getItem('level') === '1' && <div className="edit"><i className="ri-delete-bin-line" onClick={() => onDelete(data.id)} style={{color:'#da2c3e'}}></i></div>}
    </div>
  )
}