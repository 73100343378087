import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Label, Row, Spinner } from "reactstrap";
import Axios from 'axios'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select";
import './../../assets/scss/custom/components/_integration.scss'
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';

import FastAcademyCover from './../../../src/assets/images/fast-academy.png'

export default function FastAcademy(){
  const breadcrumbItems = [
    { title: "Fast Sale", link: "/dashboard" },
    { title: "Fast Academy", link: "#" },
  ]

  useEffect(() => {
    document.getElementById('load').classList.add('active')
    Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
      type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
      .then(resp => {
        document.getElementById('load').classList.remove('active')
      })
      .catch(response =>{
        localStorage.removeItem("history_url")
        setTimeout(() => {
          window.open("/login", '_parent')
        }, 300);
      })
  }, [])


  return(
    <div className="page-content" id="Campaigns">
        <Container fluid={true}>
          <Breadcrumbs title="Fast Academy" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Container>
                    <Row>
                      <Col xs={12} style={{marginBottom:'40px'}}>
                        <span style={{display:'block', marginTop:'20px'}}><h3>Transformando corretores digitais.</h3></span>
                      </Col>
                    </Row>
                    <Row className="fast-academy-card-row">
                      <Col  className="fast-academy-card-container col-auto">
                        <a href="https://youtube.com/playlist?list=PLxxkLfBCHGrBfBCQdNhdbqkahlbCqZZvI&si=A84e1IRgsNnUgIuc" target="_blank"><img className="fast-academy-card" src={FastAcademyCover}/></a>
                        <a href="https://youtube.com/playlist?list=PLxxkLfBCHGrBfBCQdNhdbqkahlbCqZZvI&si=A84e1IRgsNnUgIuc" target="_blank"><Button>Assista agora!</Button></a>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div id="load" className="load">
          <Spinner className="me-2" color="success" />
        </div>
      </div>
  )
}