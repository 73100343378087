import React, { Component } from "react";
import { Container, Row, Col, Spinner, Modal, ModalHeader, ModalBody, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import Axios from 'axios';
import Captations from './Captations';
import NextSchedulings from './NextSchedulings';
import LastViews from './LastViews';
import ContractsParc from './ContractsParc';
import Opportunity from './Opportunity';
import LastProperty from './LastProperty';
import MktBanner from './MktBanner';
import PopUps from '../../components/PopUps/PopUps';
import PendentItems from './PendentItems';
import OnLineUsers from './OnLineUsers';
import HistoryUsers from './HistoryUsers';
import FSNumbersTotal from './FSNumbersTotal';
import UsersAnalyticsBroker from './UsersAnalyticsBroker';
import UsersAnalyticsRealEstate from './UsersAnalyticsRealEstate';
import UsersAnalyticsImmobile from './UsersAnalyticsImmobile';
import LastViewOwnerTool from "../LastViewOwnerTool";
import LastDownloadOwnerTool from "../LastDownloadOwnerTool";
import SchedulingsOwner from "../SchedulingsOwner";
import ProfileToolOwner from "../ProfileToolOwner";
import UnitsUpdate from "./UnitsUpdate";
import AnalyticsEnterprises from "./AnalyticsEnterprises";
import EnterpriseLastView from "./EnterpriseLastView";
import EnterpriseTopActions from "./EnterpriseTopActions";
import EnterpriseTotalView from "./EnterpriseTotalView";
import EnterpriseUnitsTotalView from "./EnterpriseUnitsTotalView";
import EnterpriseProfileBox from "./EnterpriseProfileBox";
import SweetAlert from "react-bootstrap-sweetalert";
import EnterprisesList from "./EnterprisesList";
import ProfileWidth from './ProfileWidth';
import { Feed } from './Feed';
import { IndicateLink } from './indicateLink';
import Whats from "../../components/Whats/Whats";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Fast Sale", link : "/dashboard" },
                { title : "Dashboard", link : "#" },
            ],
            reports : [
                { icon : "ri-key-2-line", title : "Captações", value : "0", rate : "2.4%", desc : "em relação ao último mês" },
                { icon : "ri-coins-line", title : "Valor Captações", value : "R$ 0,00", rate : "2.4%", desc : "em relação ao último mês" },
            ],
            userData:[],
            ownerData:[],
            broker:[],
            img_html:false,
            totalCaptation:0,
            totalValueCaptation:0,
            totalComission:0,
            propertyPendentUpdate:false,
            propertiesPendentUpdate:[],
            propertiesLoading: {},
            verifyPremium:null,
            modal_center: false,
            data:[],
            openFeed:false,
        }
        this.tog_center = this.tog_center.bind(this);
        this.tog_pendent_property = this.tog_pendent_property.bind(this);
    }

    tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
      }

      tog_pendent_property() {
        this.setState(prevState => ({
            propertyPendentUpdate: !prevState.propertyPendentUpdate
        }));
        this.removeBodyCss();
      }
     

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

    componentDidMount(){
        document.getElementById('load').classList.add('active')
        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {


                if(localStorage.getItem('access') === 'C'){
                    Axios.get(process.env.REACT_APP_API + '/api/auth/real-estates-team/' + localStorage.getItem('id'), 
                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                      .then(response => {
                        if(response.data.corretor && response.data.corretor.approved === 0){
                            this.setState({img_html: true, broker: response.data.corretor})
                        }
                      })
                }

                if(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I'){
                    Axios.post(process.env.REACT_APP_API + '/api/auth/property-need-update', {
                        user_id: localStorage.getItem('id'),
                        user_type: localStorage.getItem('access')
                    }, 
                    {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(response => {
                       console.log(response.data)
                       if(response.data.length > 0){
                        this.setState({propertyPendentUpdate: true})
                        this.setState({propertiesPendentUpdate: response.data})
                       }
                    })
                }


                if(localStorage.getItem('access') !== 'A'){
                Axios.post(process.env.REACT_APP_API + '/api/auth/analytics', {
                    user_id: localStorage.getItem('id'),
                    type: localStorage.getItem('access')
                  }, 
                  {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(response => {
                    document.getElementById('load').classList.remove('active')
                    this.setState({userData: response.data})
                    const reports = [
                        { icon : "ri-key-2-line", title : "Captações", value : response.data.total_captacoes || 0, rate : response.data.total_captacoes_30_dias, desc : "nos últimos 30 dias" },
                        { icon : "ri-coins-line", title : "Valor Captações", value : (response.data.soma_total_captacoes / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}), rate :  (response.data.valor_captacoes_30_dias / 100000000) + "M", desc : "nos últimos 30 dias" },
                        ];
                        this.setState({
                        reports
                        })
                  })
                  Axios.post(process.env.REACT_APP_API + '/api/verify-premium', {
                    type:localStorage.getItem('access'),
                    user_id:localStorage.getItem('id')
                    }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                  .then(res => {
                    if(res.data.premium === 1 || res.data.premium === 2) {
                        this.setState({verifyPremium: true})
                      } else {
                        this.setState({verifyPremium: false})
                      }
                  })
                  .catch(response => {
                    this.setState({verifyPremium: false})
                  })
                }
                if(localStorage.getItem('access') === 'A'){
                    document.getElementById('load').classList.remove('active')
                }
                if(localStorage.getItem('access') === 'P'){

                    Axios.post(process.env.REACT_APP_API + '/api/auth/get-user/' + localStorage.getItem('id'), {
                        type: 'P'
                      }, 
                      {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}
                      })
                      .then(response => {
                        this.setState({ownerData: response.data})
                        // console.log(response.data)
                        document.getElementById('load').classList.remove('active')
                      })
                      .catch(response => localStorage.clear())
                }
                if((localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I')
                && localStorage.getItem('status') === '3'){
                    this.setState({modal_center: true})
                }
                if(localStorage.getItem('access') === 'T'){
                    document.getElementById('load').classList.remove('active')
                }

                if(localStorage.getItem('access') === 'S'){

                    document.getElementById('load').classList.remove('active')
                }
            })
            .catch(response =>{
                localStorage.removeItem("history_url")
              setTimeout(() => {
                window.open("/login", '_parent')
              }, 300);
            })
    }

    formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      handleUpdateProperty = (propertyId) => {
        this.setState(prevState => ({
            propertiesLoading: { ...prevState.propertiesLoading, [propertyId]: 'loading' }
        }));

        const formattedDate = this.formatDate(new Date());
        const data = new FormData();
        data.append('last_update', formattedDate);
        data.append('workflow', 7);

        Axios.post(`${process.env.REACT_APP_API}/api/auth/property-workflow-update/${propertyId}`, data, {
            headers: { "Authorization": "Bearer " + localStorage.getItem('token') }
        })
        .then(response => {
            // Atualiza o estado para indicar que a propriedade foi atualizada
            this.setState(prevState => ({
                propertiesLoading: { ...prevState.propertiesLoading, [propertyId]: 'updated' }
            }));
        })
        .catch(error => {
            console.error("Erro ao atualizar a propriedade:", error);
            // Remove o estado de carregamento em caso de erro
            this.setState(prevState => ({
                propertiesLoading: { ...prevState.propertiesLoading, [propertyId]: 'error' }
            }));
        });
    }
    

    render() {
        return (
            <React.Fragment>
                
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                    {(localStorage.getItem('access') === 'C' || localStorage.getItem('access') === 'I') &&
                    // <>
                    //     <Row>
                    //         <MiniWidgets reports={this.state.reports} user={this.state.userData}  premium={this.state.verifyPremium}/>
                    //     </Row>
                    //     <Row>
                    //         {/* <Col xl={8}>
                    //             <RevenueAnalytics ano_atual={this.state.userData.visualizacao_imoveis_ano?.ano_atual} ano_passado={this.state.userData.visualizacao_imoveis_ano?.ano_passado}/>
                    //         </Col> */}
                    //         <Captations imoveis={this.state.userData?.imoveis}/>
                    //         <Opportunity/>
                    //         <LastProperty premium={this.state.verifyPremium}/>
                    //     </Row>
                    //     {/* <Row>
                    //         <Col xl={6}>
                                
                    //         </Col>
                    //         <Col xl={6}>
                    //             <LastViews imoveis={this.state.userData?.ultimos_imoveis_visualizados}/>
                    //         </Col>
                    //     </Row>     */}
                    //     <Row>
                    //         {/* <NextSchedulings scheduleSend={this.state.userData?.futuras_visitas} scheduleReceive={this.state.userData?.futuras_visitas_imovel}/> */}
                            
                    //         <Col xl={4}>
                    //             <LastViews imoveis={this.state.userData?.ultimos_imoveis_visualizados}/>
                    //         </Col>
                    //         <Col xl={8}>
                    //             <ContractsParc />
                    //         </Col>
                    //     </Row>
                    // </>
                    <>
                        <Row>
                            <Col xl={4}>
                                <ProfileWidth reports={this.state.reports} user={this.state.userData}  premium={this.state.verifyPremium}/>
                            </Col>
                            <Col xl={8}>
                                <LastProperty premium={this.state.verifyPremium}/>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col xl={4}>
                                <LastViews imoveis={this.state.userData?.ultimos_imoveis_visualizados}/>
                            </Col> */}
                            <Col xl={12}>
                                <Feed openFeed={this.state.openFeed}/>
                            </Col>
                        </Row>
                    </>
                    }
                    {localStorage.getItem('access') === 'A' &&
                    <>
                        <Row>
                            <Col lg={12}>
                                <IndicateLink />
                            </Col>
                            <Col lg={4}>
                                <FSNumbersTotal />
                            </Col>
                            <Col lg={8}>
                                <PendentItems />
                            </Col>
                        </Row>
                        <Row>
                            <OnLineUsers/>
                            <HistoryUsers/>
                        </Row>
                        <Row>
                            <UsersAnalyticsBroker/>
                        </Row>
                        <Row>
                            <UsersAnalyticsRealEstate/>
                        </Row>
                        <Row>
                            <UsersAnalyticsImmobile/>
                        </Row>
                    </>
                    }
                    {(localStorage.getItem('access') === 'T' || localStorage.getItem('access') === 'S') &&
                    <>
                        <Row>
                            <Col xs={12}>
                                <EnterpriseProfileBox/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <EnterprisesList/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <EnterpriseTopActions/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} lg={4}>
                                <EnterpriseLastView/>
                            </Col>
                            <Col md={6} lg={4}>
                                <EnterpriseTotalView/>
                            </Col>
                            <Col md={6} lg={4}>
                                <EnterpriseUnitsTotalView/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Feed openFeed={this.state.openFeed}/>
                            </Col>
                        </Row>
                    </>
                    }
                    {localStorage.getItem('access') === 'P' &&
                    <>
                        <Row>
                            <Col lg={4} md={6} xs={12} style={{marginBottom:'20px'}}>
                                <ProfileToolOwner user={this.state.ownerData}/>
                            </Col>
                            <Col lg={8} md={6} xs={12} style={{marginBottom:'20px'}}>
                                <RevenueAnalytics ano_atual={this.state.userData.visualizacao_imoveis_ano?.ano_atual} ano_passado={this.state.userData.visualizacao_imoveis_ano?.ano_passado}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={6} xs={12} style={{marginBottom:'20px'}}>
                                <LastViewOwnerTool user={this.state.ownerData}/>
                            </Col>
                            <Col lg={4} md={6} xs={12} style={{marginBottom:'20px'}}>
                                <LastDownloadOwnerTool user={this.state.ownerData}/>
                            </Col>
                            <Col lg={4} md={6} xs={12} style={{marginBottom:'20px'}}>
                                <SchedulingsOwner user={this.state.ownerData}/>
                            </Col>
                        </Row>
                    </>
                    }
                    </Container>
                    
                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                    {(localStorage.getItem('access') !== 'A' && localStorage.getItem('access') !== 'P' && localStorage.getItem('access') !== 'T') &&
                        localStorage.getItem('status') !== '3' &&
                            <PopUps
                                verifyPremium={this.state.verifyPremium}
                                ctaAction={() => this.setState({openFeed : !this.state.openFeed})}
                            />
                    }
                </div>

                
                {this.state.img_html ? (
                    <SweetAlert
                        title={`A imobiliária ${this.state.broker?.real_estate?.name} te convidou para seu time`}
                        warning
                        showCancel
                        confirmBtnText="Aceitar"
                        cancelBtnText="Negar"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="dark"
                        onConfirm={() =>
                            Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-update', {
                                id: this.state.broker?.id,
                                broker_id: localStorage.getItem('id'),
                                real_estate_id: this.state.broker?.real_estate_id,
                                approved: 1
                            },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                            .then(response => {
                                this.setState({
                                    img_html:false
                                })
                            })
                        }
                        onCancel={() =>
                            Axios.post(process.env.REACT_APP_API + '/api/auth/real-estates-team-update', {
                                id: this.state.broker?.id,
                                broker_id: localStorage.getItem('id'),
                                real_estate_id: this.state.broker?.real_estate_id,
                                approved: 2
                            },{headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
                            .then(response => {
                                this.setState({
                                    img_html:false
                                })
                            })
                        }
                        >
                        Ao aceitar você ganhará os benefícios da asinatura da imobiliária.
                    </SweetAlert>
                ) : null}

                <Modal
                    isOpen={this.state.modal_center}
                    toggle={this.tog_center}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.setState({ modal_center: false })}
                    style={{backgroundColor:"#ed4c4b", minHeight:'120px'}}>
                    <h3 style={{color:"#fff"}}>Atenção!</h3>
                    </ModalHeader>
                    <ModalBody style={{textAlign:'center'}}>
                    <p>Valide seu cadastro para liberar as funcionalidades do painel de controle Fast Sale.</p>
                    <br></br>
                    <p style={{fontSize:'18px', fontWeight:'600'}}>Restam {localStorage.getItem('limit_date') ? localStorage.getItem('limit_date') : 'XX'} dias.</p>
                    <br></br>
                    <Button onClick={() => {
                        window.open('./editar-perfil', '_parent')
                    }}>Validar agora</Button>
                    </ModalBody>
                </Modal>

                {/* Modal Imóvel Pendente atualização */}

                                {/* this.setState({propertyPendentUpdate: true})
                        this.setState({propertiesPendentUpdate: response.data}) */}
                <Modal
                    isOpen={this.state.propertyPendentUpdate}
                    toggle={this.tog_pendent_property }
                    centered={true}
                    size='lg'
                >
                    <ModalHeader toggle={this.tog_pendent_property }
                    style={{backgroundColor:"#ed4c4b", minHeight:'80px'}}>
                    <h3 style={{color:"#fff"}}>Atenção!</h3>
                    </ModalHeader>
                    <ModalBody style={{textAlign:'center', listStyle:'none'}}>
                        Os seguintes imóveis estão pendente de atualização, atualize para evitar que sejam bloqueados.
                        <div style={{margin:'20px 0 0'}}>
                        {this.state.propertiesPendentUpdate.map((property, index) => (
                            <li className="need-update-property-item" key={index}>

                                <div className="property-photo" style={{cursor:"pointer"}} onClick={() => {
                                    setTimeout(() => {
                                        window.open("../../../../../imovel/" + property.slug, '_blank')
                                    }, 0);
                                }}><img src={property.photos[0]?.small_image || ''} style={{width:'100%', height:'100%', objectFit:'cover'}}/></div>
                                <div className="property-info">
                                    <div className="sku">{property.sku} | {property.address_properties.enterprise}</div>
                                    <div className="property-unity">Unidade: {property.address_properties.unities}</div>
                                    {property.sell_price && property.sell_price !== 0 ?
                                        <div className="property-promo-price">
                                            <p className="original-price">{(property.sale_value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            <p className="promo-price">{(property.sell_price / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                    :
                                        <div className="property-promo-price">
                                            <p className="promo-price">{(property.sale_value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                    }
                                    <div className="property-actions">
                                        <div className="btn-1"><Button onClick={() => {
                                            setTimeout(() => {
                                                window.open('/editar-imovel?' + property.id, '_parent')
                                            }, 0);
                                        }}>Atualizar agora</Button></div>
                                        <div className="btn-2">
                                            <Button
                                                onClick={() => this.handleUpdateProperty(property.id)}
                                                disabled={this.state.propertiesLoading[property.id] === 'updated'}
                                            >
                                                {this.state.propertiesLoading[property.id] === 'loading' ? (
                                                    <Spinner size="sm" />
                                                ) : this.state.propertiesLoading[property.id] === 'updated' ? (
                                                    "Atualizado"
                                                ) : (
                                                    "Está atualizado"
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                        </div>
                        {/* <Button onClick={() => {
                            setTimeout(() => {
                                window.open('/editar-imoveis', '_parent')
                            }, 100);
                        }}>Atualizar imóveis</Button> */}
                    </ModalBody>
                </Modal>
                
            </React.Fragment>
        );
    }
}

export default Dashboard;
