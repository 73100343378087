import { Button, Card, CardBody, Col, Container, Input, Row, Spinner } from 'reactstrap';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { AuthComponent } from '../../Authentication/Login/components/authComponent';
import { LoginModal } from './components/loginModal';
import { RegisterModal } from './components/registerModal';
import Axios from 'axios';
import LogoPix from './../../../assets/images/pix.jpg'
import LogoCard from './../../../assets/images/cartao-de-credito.png'
import { CreditCardForm } from './components/creditCardForm';
import { SelectedPlan } from './components/selectedPlan';
import logoDark from "../../../assets/images/logo-dark.png";
import { PaymentError } from './components/errorsModal';
import { NoCompatibleProduct } from './components/noCompatibleProduct';
import Whats from '../../../components/Whats/Whats';

export const NewCheckout = () => {
  const [logged, setLogged] = useState(false)
  const [loggedName, setLoggedName] = useState('')
  const [loggedEmail, setLoggedEmail] = useState('')
  const [loginOpen, setLoginOpen] = useState(false)
  const [registerOpen, setRegisterOpen] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState('')
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [errorOpen, setErrorOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const [singleError, setSingleError] = useState('')
  const [pixUrl, setPixUrl] = useState(null);
  const [loading, setLoading] = useState(false)
  const [compatibleOpen, setCompatibleOpen] = useState(false)
  const [applyCoupon, setApplyCounpon] = useState(null)

  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search);
  const product = params.get('product');
  const recurrence = params.get('recurrence');
  const coupon = params.get('coupon')
  const afType = params.get('aftype');
  const afOrigin = params.get('aforigin');
  const afId = params.get('afid');

  const handleSuccess = () => {
    window.location.reload()
  }

  const handleAff = () => {
    const affiliate ={
      user_type: localStorage.getItem('access'),
      user_id: localStorage.getItem('id'),
      affiliate_type: afType,
      affiliate_id: afId,
      origin: afOrigin
    }

    const updateAffiliate ={
      origin: afOrigin
    }

    Axios.post(process.env.REACT_APP_API + '/api/register/affiliate-indicator', affiliate,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }).catch(error => {
      if (error.response.data.existing_id) {
        const existingId = error.response.data.existing_id;
  
        Axios.post(`${process.env.REACT_APP_API}/api/auth/indicator-update/${existingId}`, updateAffiliate, {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
      } else {
        console.error('Error creating Affiliate Indicator:', error);
      }
    });
  }

  const handleCardSend = (card, cpf, parcels) => {
    setSingleError('')
    setErrors([])
    setLoading(true)
    if(afType && afOrigin && afId){
      handleAff()
    }

    const data = new FormData()
    data.append('product_id', product)
    data.append('recurrence', recurrence)
    if(applyCoupon){
      data.append('coupon', applyCoupon)
    }
    data.append('type', localStorage.getItem('access'))
    data.append('payment', 'card')
    data.append('user_id', localStorage.getItem('id'))        
    data.append('installments', parcels)        
    data.append('cpf', cpf.replaceAll('.' , '').replace('-', ''))        
    data.append('number', card.number.replaceAll(' ', ''))        
    data.append('holder_name', card.name)        
    data.append('exp_month', card.expiry.split('/')[0])        
    data.append('exp_year', card.expiry.split('/')[1])        
    data.append('cvv', card.cvc)        
    Axios.post(process.env.REACT_APP_API + '/api/auth/new-payment', data, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    }).then(res => {
      if(res.data.errors){
        setErrorOpen(true)
        setErrors(res.data.errors)
      }else if(res.data.message == "CVV is Required"){
        setErrorOpen(true)
        setSingleError('Código de segurança é obrigatório.')
      }else if(res.data.message == "Could not be created the subscription because Could not create credit card. The card verification failed."){
        setErrorOpen(true)
        setSingleError('A verificação do cartão falhou, confira os dados e tente novamente.')
      }else{
        history.push(`/compra-finalizada?id=${res.data.id}`)
      }

      setLoading(false)
    }).catch(error => {
      setErrorOpen(true)
      if(error.response.data.error === 'Invalid or missing cep'){
        setSingleError('cep')
      }else{
        setSingleError('fatal')
      }
    })
  }

  const handlePixSend = () => {
    setSingleError('')
    setErrors([])
    setLoading(true)
    if(afType && afOrigin && afId){
      handleAff()
    }

    const data = new FormData()
    data.append('product_id', product)
    data.append('type', localStorage.getItem('access'))
    data.append('payment', 'pix')
    if(applyCoupon){
      data.append('coupon', applyCoupon)
    }
    data.append('recurrence', recurrence)
    data.append('user_id', localStorage.getItem('id'))                  
    Axios.post(process.env.REACT_APP_API + '/api/auth/new-payment', data, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    }).then(res => {
      // setErrorOpen(true)
      setPixUrl({
        qr_code: res.data.charges[0].last_transaction.qr_code,
        qr_code_url: res.data.charges[0].last_transaction.qr_code_url,
        transition_id: res.data.charges[0].id
    })
      setLoading(false)
    }).catch(error => {
      setErrorOpen(true)
      if(error.response.data.error === 'Invalid or missing cep'){
        setSingleError('cep')
      }else{
        setSingleError('fatal')
      }
    })
  }

  useEffect(() => {
    if(localStorage.getItem('token')){
      Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
          type: localStorage.getItem('access'),
          user_id: localStorage.getItem('id'),
        }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
          setLogged(true)
          setLoggedName(localStorage.getItem('user_name'))
          setLoggedEmail(localStorage.getItem('user_email'))
        }).catch(error => {
          setLogged(false)
        })
    }
  }, [])

  useEffect(() => {
    Axios.get(process.env.REACT_APP_API + '/api/public/product/' + product)
    .then(response => {
      setSelectedProduct(response.data)
    })
  }, [product])

  const handleCopy = () => {

  }

  // useEffect(() => {
  //   if(selectedProduct && logged){
  //     if(selectedProduct.user_type === localStorage.getItem('access')){
  //       setCompatibleOpen(false)
  //     }else{
  //       setCompatibleOpen(true)
  //     }
  //   }
  // }, [selectedProduct, logged])

  const handleCoupon = (couponId) => {
    setApplyCounpon(couponId)
  }

  return(
    <div className="checkout-cart-section">
      <Container>
        <Row className="top-bar">
          <Col className="col-auto">
            <img src={logoDark}/>
          </Col>
          <Col className="col-auto">
            <span>Checkout Seguro</span>
            <i className="ri-lock-fill"></i>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="reverse">
          <Col md={8}>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className="account-container">
                      <div className="account-row">
                        <div className="logged">
                          {logged ?
                            <span><i className="ri-checkbox-circle-fill"></i></span>
                            :
                            <span className="step">1</span>
                          }
                          {logged ?
                            <h4>Conta conectada</h4>
                            :
                            <h4>Conectar sua conta</h4>
                          }
                        </div>
                        <div className="alter">
                          {logged &&
                            <span  onClick={() => {
                              localStorage.clear()
                              setTimeout(() => {
                                window.location.reload()
                              }, 300)
                            }}>Alterar conta</span>
                          }
                        </div>
                      </div>
                      <div className="account-row">
                        {logged ?
                          <div className="logged-account">
                            <p>Você está logado como:</p>
                            <p>{loggedName} ({loggedEmail})</p>
                          </div>
                        :
                          <div className="login-account">
                            <button onClick={() => setLoginOpen(true)}>Fazer login</button>
                            <p>Ainda não tem uma conta? <span onClick={() => setRegisterOpen(true)}>Cadastre-se</span></p>
                          </div>
                        }
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className="payment-container">
                      <div className="payment-row">
                        <div className={`logged ${!logged ? 'inactive' : ''}`}>
                          <span className="step">2</span>
                          <h4>Forma de pagamento</h4>
                        </div>
                      </div>
                      {loading ? 
                        <Spinner className="me-2" color="success" />
                        :
                        <>
                        {logged &&
                          <div className="payment-row">
                            <div className="payment-method-container">
                              {recurrence !== 'month' &&
                                <div
                                  className={`payment-method-option ${selectedPayment === 'pix' ? 'active' : ''}`}
                                  onClick={() => setSelectedPayment('pix')}
                                >
                                  <span style={{backgroundImage: `url('${LogoPix}')`}}></span>
                                  <div className="year-pix-offer">-5%</div>
                                </div>
                              }
                              <div
                                className={`payment-method-option credit-card ${selectedPayment === 'credit-card' ? 'active' : ''}`}
                                onClick={() => setSelectedPayment('credit-card')}
                              >
                                <img src={LogoCard}/>
                                <p>Cartão de crédito</p>
                              </div>
                            </div>
                          </div>
                        }
                        {selectedPayment === 'pix' &&
                          <div className="pix-container">
                            {!pixUrl ?
                              <Button onClick={handlePixSend}>Gerar QR Code</Button>
                              :
                              <>
                              <div className="code-pix-container">
                                <p>Este QR Code ficara válido pelos próximos 15 minutos</p>
                                <div className="code-pix">
                                    <img src={pixUrl?.qr_code_url} alt="" />
                                </div>
                                <div className="input-code">
                                    <input className='qr-code' type="text" name="" id="" value={pixUrl?.qr_code} />
                                    <button className='copy-btn' onClick={handleCopy}><i className="ri-file-copy-line"></i></button>
                                </div>
                              </div>
                              <a href={`/compra-finalizada?id=${pixUrl?.transition_id}&method=pix`}><button className="paid-pix-btn">Já paguei</button></a>
                              </>
                            }
                          </div>
                        }
                        {selectedPayment === 'credit-card' &&
                          <div className="credit-card-container">
                            <CreditCardForm onSend={handleCardSend} product={selectedProduct} recurrence={recurrence} coupon={applyCoupon}/>
                          </div>
                        }
                      </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                {selectedProduct &&
                  <SelectedPlan product={selectedProduct} recurrence={recurrence} coupon={coupon} onApplyCoupon={handleCoupon}/>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <NoCompatibleProduct open={compatibleOpen} onClose={() => history.push('/carrinho')} />
      
      <LoginModal open={loginOpen} onClose={() => setLoginOpen(false)} success={handleSuccess} checkout={[product, recurrence]}/>
      <RegisterModal open={registerOpen} onClose={() => setRegisterOpen(false)}/>
      
      <PaymentError open={errorOpen} onClose={() => setErrorOpen(false)} errors={errors} singleError={singleError}/>
      <Whats/>
    </div>
  )
}