import './styles.scss';

export const CouponListItem = ({data, title = false, onDelete, onEdit}) => {

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      };

    return(
        title ?
        <div className="coupon-list-item title">
          <div className="coupon-code">Cupom</div>
          <div className="coupon-description">Descrição</div>
          <div className="coupon-owner-name">Dono do Cupom</div>
          <div className="coupon-owner-type">Tipo de dono</div>
          <div className="coupon-discount">Desc.</div>
          <div className="coupon-created">Criação</div>
          <div className="coupon-created">Validade</div>
          <div className="coupon-uses">Usos</div>
          <div className="coupon-status">Status</div>
          {localStorage.getItem('level') === '1' && <div className="edit">Editar</div>}
          {localStorage.getItem('level') === '1' && <div className="edit">Excluir</div>}
        </div>
        :
        <div className="coupon-list-item">
            <div className="coupon-code">{data.name}</div>
            <div className="coupon-description">{data.description}</div>
            <div className="coupon-owner-name">
                {data.user_type === 'C' && data.broker?.name}
                {data.user_type === 'I' && data.realEstate?.name}
                {data.user_type === 'A' && data.user?.name}
                {data.user_type === 'T' && data.construction?.name}
            </div>
            <div className="coupon-owner-type">
                {data.user_type === 'C' && 'Corretor'}
                {data.user_type === 'I' && 'Imobiliária'}
                {data.user_type === 'A' && 'Admin'}
                {data.user_type === 'P' && 'Proprietário'}
                {data.user_type === 'T' && 'Construtora'}
                {data.user_type === 'S' && 'Construtora'}
            </div>
            <div className="coupon-discount">{data.discount}%</div>
            <div className="coupon-created">{formatDate(data.created_at)}</div>
            <div className="coupon-created">{data.maturity}</div>
            <div className="coupon-uses">{data.uses_count}</div>
            <div className="coupon-status">{data.status}</div>
            {localStorage.getItem('level') === '1' && <div className="edit"><i className="ri-edit-line" onClick={onEdit}></i></div>}
            {localStorage.getItem('level') === '1' && <div className="edit"><i className="ri-delete-bin-line" onClick={onDelete} style={{color:'#da2c3e'}}></i></div>}
        </div>
    )
}