import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    Container,
    Spinner,
    ModalBody,
} from "reactstrap"
import { createTheme, Pagination, ThemeProvider } from '@mui/material';


//css
import "@fullcalendar/bootstrap/main.css"
import './../../assets/scss/custom/components/_catalogProperties.scss'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Axios from 'axios'

import 'swiper/css';
import "swiper/css/navigation";
// import "swiper/scrollbar.css"

import AllMaps from './AllMaps'
import EnterpriseCard from "../../components/ImovelCard/EnterpriseCard"
import UnitCard from "../../components/ImovelCard/UnitCard"
import ImovelCard from "../../components/ImovelCard/ImovelCard"


import Todos from './../../assets/images/Icons/unidades.png';
import Terceiros from './../../assets/images/Icons/terceiros.png';
import Empreendimentos from './../../assets/images/Icons/empreendimentos.png';
import BannerConstrutora from './../../assets/images/banner-construtora.png';
import { Campaign } from "./icons/campaign"
import { Delivery } from "./icons/delivery"
import { Property } from "./icons/properties"
import { Enterprise } from "./icons/enterprise"

const urlSection = window.location.search.replace('?', '')

class CatalogConstructionResult extends Component {
    constructor(props) {
        super(props)

        this.state = {
            breadcrumbItems: [
                { title: "Fast Sale", link: "/dashboard" },
                { title: "Construtora", link: "#" },
            ],
            modal_scroll: false,
            visible: false,
            verifyPremium: 0,
            sortResults: localStorage.getItem('filtro-sort') ? localStorage.getItem('filtro-sort') : 'newest',
            searchContractType: localStorage.getItem('search-contract-type') ? localStorage.getItem('search-contract-type') : 'Todos Contratos',
            imoveis: [],
            empreendimentos: [],
            construtoras:[],
            construtora:[],
            unidades:[],
            favoritos:[],
            modal_large: false,
            page: localStorage.getItem('paginaAtual') ? localStorage.getItem('paginaAtual') : 1,
            search: '',
            atualPage:1,
            dataFilter:[],
            unidades:[],
            todos:[],
            oportunidade:[],
            imoveisCarregando:null,
            pages: 1,
            profile_bg:'',
            searchCity:'',
            modal_about: false,
            searchConstruction:'',
            activeTab:'Empreendimentos',
            searchEnterprise:'',
            showFilter:{
                filter_lg: 4,
                filter_xl: 3,
                body_lg: 8,
                body_xl: 9,
            },
            onToogle:true,
        }
        this.buttonRef = React.createRef();
        this.tog_scroll = this.tog_scroll.bind(this);
        this.tog_large = this.tog_large.bind(this);
        this.tog_about = this.tog_about.bind(this);
    }

    tog_large() {
        this.setState(prevState => ({
            modal_large: !prevState.modal_large
        }));
        this.removeBodyCss();
    }

    tog_about() {
        this.setState(prevState => ({
            modal_about: !prevState.modal_about
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_scroll() {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll
        }));
        // this.removeBodyCss();
    }

    show() {
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }



    componentDidMount = () => {
        document.getElementById('load').classList.add('active')
        setTimeout(() => {
            window.scrollTo(0,0)
        }, 300);

        Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
            type: localStorage.getItem('access'),
            user_id: localStorage.getItem('id'),
            }, {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {

                this.handleConstructions();
                this.handleTerceiros();

        })
        .catch(response =>{
            localStorage.removeItem("history_url")
                setTimeout(() => {
                window.open("/login", '_parent')
            }, 300);
        })

    }


    handleConstructions = () => {
        document.getElementById('load').classList.add('active')
        Axios.get(process.env.REACT_APP_API + '/api/auth/enterprise/constructions-min/' + urlSection,
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
        .then(response => {
            this.setState({construtoras: response.data.enterprises})
            this.setState({construtora: response.data})
            // this.setState({pages: response.data.last_page})
            setTimeout(() => {
                document.getElementById('load').classList.remove('active')
            }, 300);


        const data = new FormData()
    if(localStorage.getItem('access') === 'C'){data.append('broker_id', localStorage.getItem('id'))}
        if(localStorage.getItem('access') === 'I'){data.append('real_estate_id', localStorage.getItem('id'))}
        data.append('construction_id', response.data.id)

        Axios.post(process.env.REACT_APP_API + '/api/auth/enterprise/construction-views', data,
        {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})

        })
    }
    
    handleTerceiros = () => {
        document.getElementById('load').classList.add('active')
        // const input = document.getElementById("inputText");
        // input.addEventListener("keydown", this.handleKeyDown);

        const data = {
            categories: (localStorage.getItem('filtro-categoria') !== null && localStorage.getItem('filtro-categoria').length !== 0) ? localStorage.getItem('filtro-categoria').split(",") : 0,
            localization: (localStorage.getItem('filtro-cidades') !== null && localStorage.getItem('filtro-cidades').length !== 0) ? localStorage.getItem('filtro-cidades').split(",") : 0,
            dorms_number: localStorage.getItem('filtro-quartos') !== null ? (localStorage.getItem('filtro-quartos') !== '' ? localStorage.getItem('filtro-quartos').split(",") : 0) : 0,
            parking_spots: localStorage.getItem('filtro-garagens') !== null ? (localStorage.getItem('filtro-garagens') !== '' ? localStorage.getItem('filtro-garagens').split(",") : 0) : 0,
            real_state_financing:localStorage.getItem('filtro-negocio')?.includes('Financiamento Bancário') ? 1 : 0,
            accept_object_exchange:localStorage.getItem('filtro-negocio')?.includes('Permuta Objeto') ? 1 : 0,
            accept_properties:localStorage.getItem('filtro-negocio')?.includes('Permuta Imóvel') ? 1 : 0,
            min_value:localStorage.getItem('min_price_range_filter') ? localStorage.getItem('min_price_range_filter') : '',
            max_value:localStorage.getItem('max_price_range_filter') ? localStorage.getItem('max_price_range_filter') : '',
            min_area:localStorage.getItem('min_area_range_filter') ? localStorage.getItem('min_area_range_filter') : '',
            max_area:localStorage.getItem('max_area_range_filter') ? localStorage.getItem('max_area_range_filter') : '',
            filter:decodeURI(this.state.sortResults),
            search: localStorage.getItem('filter-search'),
            user_type:'T',
            contract:'Construtoras',
            user_id:urlSection,
            accept_car:localStorage.getItem('filtro-negocio')?.includes('Permuta Automóvel') ? 1 : 0
        }
        this.setState({dataFilter: data})
            Axios.post(process.env.REACT_APP_API + '/api/public/property-filter-user/?page=' + this.state.page, data, 
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                    this.setState({todos: response.data.data})
                    this.setState({pages: response.data.last_page})
                    this.setState({imoveisCarregando: 'Em breve.'})
                this.setState({quantidadeResultados: response.data.total})
                setTimeout(() => {
                    document.getElementById('load').classList.remove('active')
                }, 300);
        })
    }

    generateWhatsAppLink(rawNumber) {
        const numberStr = rawNumber.toString();
        const formattedNumber = `(${numberStr.slice(0, 2)}) ${numberStr.slice(2, 7)}-${numberStr.slice(7)}`;
        const whatsappLink = `https://wa.me/${numberStr}?text=Olá! Conheci a construtora através da Fast Sale.`;

        return { link: whatsappLink, display: formattedNumber };
    }


    render() {
        const theme = createTheme({
            palette: {
            verde: {
                main: '#3E5E3F',
                contrastText: '#fff',
                },
            },
        });

        const { link, display } = this.generateWhatsAppLink(this.state.construtora?.phone || '');

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={'Construtora'} breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>
                            <Card>
                                <CardBody>
                                <div className="constructionHeader">
                                    <div className="faixa" style={{
                                        backgroundImage: `url('${this.state.construtora?.profile_bg ? this.state.construtora?.profile_bg : BannerConstrutora}')`
                                    }}></div>
                                    <div className={'row'}>
                                        <div className={'col-right'}>
                                            <div className="constructionCover" style={{backgroundImage:'url(' + this.state.construtora?.profile_photo + ')'}}></div>
                                            <div className="constructionName">{this.state.construtora?.name}</div>
                                        </div>
                                        <div className={'col-left'}>
                                        {this.state.construtora?.instagram && <div className="constructionInstagram"><a href={`https://instagram.com/${this.state.construtora?.instagram?.replace('@', '')}`} target="_blank"><i className="ri-instagram-line"></i>{this.state.construtora?.instagram}</a></div>}
                                            <div className="constructionWhats"><a href={link} target="_blank"><i className="ri-whatsapp-line"></i>{display}</a></div>
                                            {this.state.construtora?.site && <div className="constructionSite-mobile"><a href={`https://${this.state.construtora?.site?.replace('https://', '')}`} target="_blank"><i className="ri-global-line"></i>{this.state.construtora?.site}</a></div>}
                                            {this.state.construtora?.instagram && <div className="constructionInstagram-mobile"><a href={`https://instagram.com/${this.state.construtora?.instagram?.replace('@', '')}`} target="_blank"><i className="ri-instagram-line"></i>{this.state.construtora?.instagram}</a></div>}
                                            {this.state.construtora?.about && <span onClick={this.tog_about}>Sobre</span>}
                                        </div>
                                        <div className={'col-left'}>
                                            {this.state.construtora?.site && <div className="constructionSite"><a href={`https://${this.state.construtora?.site?.replace('https://', '')}`} target="_blank"><i className="ri-global-line"></i>{this.state.construtora?.site}</a></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="constructionPlace"><i className="ri-map-pin-2-fill"></i>
                                    {(this.state.construtora?.street && this.state.construtora?.street !== 'null') && this.state.construtora?.street + ', '}
                                    {(this.state.construtora?.number && this.state.construtora?.number !== 'null') && this.state.construtora?.number + ', '}
                                    {(this.state.construtora?.complement && this.state.construtora?.complement !== 'null') && this.state.construtora?.complement + ', '}
                                    {(this.state.construtora?.district && this.state.construtora?.district !== 'null') && this.state.construtora?.district + ' - '}
                                    {(this.state.construtora?.city && this.state.construtora?.city !== 'null')}/{this.state.construtora?.state}</div>                                    
                                <div>

                                <Row style={{marginTop: '20px'}}>
                                    <Col className="tabList">
                                        <div className={"contractTabs construction"}>

                                            <div id="fs" className={"tab fs" + " " + (this.state.activeTab === 'Empreendimentos' && 'active')} onClick={() => {                                               
                                                this.setState({activeTab : 'Empreendimentos'})
                                            }}>
                                                {/* <div className="tab-icon" style={{backgroundImage:'url(' + Empreendimentos + ')'}}></div> */}
                                                <span className="mobile-tab-icon"><Enterprise/></span>
                                                <span>Empreendimentos</span>
                                            </div>

                                            <div id="outlet" className={"tab fs" + " " + (this.state.activeTab === 'Entregues' && 'active')} onClick={() =>
                                            {this.setState({activeTab : 'Entregues'})}}>
                                                {/* <div className="tab-icon" style={{backgroundImage:'url(' + Todos + ')'}}></div> */}
                                                <span className="mobile-tab-icon"><Delivery/></span>
                                                <span>Entregues</span>
                                            </div>

                                            <div id="fs" className={"tab fs" + " " + (this.state.activeTab === 'Terceiros' && 'active')} onClick={() => {                                               
                                                this.setState({activeTab : 'Terceiros'})
                                            }}>
                                                {/* <div className="tab-icon" style={{backgroundImage:'url(' + Terceiros + ')'}}></div> */}
                                                <span className="mobile-tab-icon"><Property/></span>
                                                <span>Terceiros</span>
                                            </div>
                                            
                                            {this.state.construtora?.campaign &&
                                            <div id="fs" className={"tab fs" + " " + (this.state.activeTab === 'Campanhas' && 'active')} onClick={() => {                                               
                                                this.setState({activeTab : 'Campanhas'})
                                            }}>
                                                {/* <div className="tab-icon" style={{backgroundImage:'url(' + Terceiros + ')'}}></div> */}
                                                <span className="mobile-tab-icon"><Campaign/></span>
                                                <span>Campanhas</span>
                                            </div>
                                            }
                                        </div>
                                    </Col>
                                    </Row>

                                    {this.state.activeTab === 'Empreendimentos' &&
                                    (this.state.construtoras?.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                            {this.state.construtoras.map((imovel, index) => (
                                                <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                    <EnterpriseCard  imovel={imovel} favoritos={this.state.favoritos} premium={this.state.verifyPremium}/>
                                                </Col>
                                            ))}

                                            </Row>
                                        :
                                            <Row>
                                                <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                            </Row>)
                                    }

                                    {this.state.activeTab === 'Entregues' &&
                                    (this.state.construtoras?.filter(enterprises => enterprises.progress === 100)?.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                            {this.state.construtoras.filter(enterprise => enterprise.progress === 100).map((imovel, index) => (
                                                <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                    <EnterpriseCard  imovel={imovel} favoritos={this.state.favoritos} premium={this.state.verifyPremium}/>
                                                </Col>
                                            ))}

                                            </Row>
                                        :
                                            <Row>
                                                <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                            </Row>)
                                    }

                                    {this.state.activeTab === 'Terceiros' &&
                                    (this.state.todos?.length > 0 ?
                                        <Row className="catalogResult" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', display:'grid', gap: '10px'}}>
                                            {this.state.todos.map((imovel, index) => (
                                                <Col key={index} style={{padding:'0', justifyContent:'center', display: 'flex', alignItems: 'center'}}>
                                                    <ImovelCard  imovel={imovel} favoritos={this.state.favoritos} premium={this.state.verifyPremium}/>
                                                </Col>
                                            ))}

                                            </Row>
                                        :
                                            <Row>
                                                <Col style={{padding:'20px', textAlign:'center'}}>{this.state.imoveisCarregando}</Col>
                                            </Row>)
                                    }

                                    {this.state.activeTab === 'Campanhas' &&
                                        <a href={this.state.construtora?.campaign || ''} target="_blank"><div className="construction-campaign">
                                            <h3>{this.state.construtora.campaign_title}</h3>
                                            <img src={this.state.construtora?.campaign || ''}/>
                                            <p>Salvar</p>
                                        </div></a>
                                    }

                                    {/* 
                                    <Row>
                                        <Col>
                                            <ThemeProvider theme={theme}>
                                                <Pagination
                                                count={this.state.pages}
                                                color="verde"
                                                size="small" 
                                                page={parseInt(this.state.page)}
                                                style={{margin:'20px 0', display:'flex', justifyContent:'flex-end'}}
                                                onChange={(event, value) => {
                                                    localStorage.setItem('paginaAtual', value)
                                                    this.setState({page: value})
                                                    setTimeout(() => {
                                                            this.handleConstructions();
                                                    }, 300);
                                                }}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                    </Row> */}
                                </div>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>

                        {/* {((localStorage.getItem('status') !== '3' && localStorage.getItem('status') !== '2') &&
                        (this.state.activeTab === 'Terceiros')) &&
                            <Button id="btnMap" className="btn-dark" style={{display:'flex', alignItems:'center', gap:'10px'}} onClick={() => {
                                this.setState({ modal_large: true })
                                document.getElementById('load').classList.add('active')
                            }}>
                                <i className="ri-map-pin-2-fill"></i>
                                Ver no Mapa
                            </Button>
                        } */}

                    </Container>

                    {/* PopUp Sobre Construtora */}

                    <Modal
                        size="lg"
                        centered={true}
                        isOpen={this.state.modal_about}
                        toggle={this.tog_about}
                    >
                        <ModalHeader toggle={() => this.setState({ modal_about: false })}>
                            <h3 style={{fontSize:'16px'}}>{this.state.construtora?.name}</h3>
                        </ModalHeader>
                        <ModalBody>
                            <p>{this.state.construtora?.about}</p>
                        </ModalBody>
                    </Modal>

                    {/* PopUp Google Maps */}

                    <Modal
                        size="lg"
                        centered={true}
                        isOpen={this.state.modal_large}
                        toggle={this.tog_large}
                    >
                        <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                        </ModalHeader>
                        {/* <ModalBody> */}
                            <AllMaps dataFilter={this.state.dataFilter} zoomLevel={13}/>
                        {/* </ModalBody> */}
                    </Modal>

                    <div id="load" className="load">
                        <Spinner className="me-2" color="success" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default (CatalogConstructionResult)
// export default connect(mapStateToProps, mapDispatchToProps)(Calender)