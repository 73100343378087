import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import './../../assets/scss/custom/components/_allMaps.scss';

const LocationPin = ({ price, slug, text, photo, bed, garage, address }) => (
  text ? (
    <div className="cityQtd"><span className="pinCity"><i className="ri-home-2-fill"></i>{text}</span></div>
  ) : (
    <Link to={`/imovel/${slug}`} target="_blank">
      <div className="raio">
        <span className="price">R$ {(price / 100000000).toFixed(2)} M</span>
        <div className="imovelCard">
          <div className="cover" style={{ backgroundImage: `url(${photo?.small_image})` }}></div>
          <div className="infos">
            <div className="bed"><i className="ri-hotel-bed-fill"></i>{bed}</div>
            <div className="garage"><i className="ri-car-fill"></i>{garage}</div>
          </div>
          <div className="address">
            <small>{address?.city}/{address?.state}</small>
          </div>
        </div>
      </div>
    </Link>
  )
);

function AllMaps({ dataFilter }) {
  const [latCenter, setLatCenter] = useState(0);
  const [lngCenter, setLngCenter] = useState(0);
  const [zoomVariation, setZoomVariation] = useState(13);
  const [userCity, setUserCity] = useState(localStorage.getItem('user_city') || 'Balneário Camboriú');
  const [results, setResults] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const geoResponse = await Axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${userCity}&key=${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`
        );
        setLatCenter(geoResponse.data.results[0]?.geometry.location.lat || 0);
        setLngCenter(geoResponse.data.results[0]?.geometry.location.lng || 0);

        const resultsResponse = await Axios.post(
          `${process.env.REACT_APP_API}/api/public/property-filter-no-pagination-for-map`,
          dataFilter,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setResults(resultsResponse.data);

        const cityResponse = await Axios.get(
          `${process.env.REACT_APP_API}/api/public/cities`,
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setCityList(cityResponse.data);
      } catch (error) {
        console.error('Error fetching map data:', error);
      }
    };

    if (dataFilter) {
      fetchData();
    }
  }, [dataFilter, userCity]);

  useEffect(() => {
    if(latCenter !== 0 && results.length > 0){
      setTimeout(() => {
        document.getElementById('load').classList.remove('active')
      }, 500);
    }
  }, [latCenter, results])

  const renderPins = () => {
    if (zoomVariation > 12) {
      return results.map((imovel, index) =>
        imovel?.latitude ? (
          <LocationPin
            key={index}
            lat={parseFloat(imovel.latitude)}
            lng={parseFloat(imovel.longitude)}
            price={imovel.sell_price || imovel.sale_value}
            slug={imovel.slug}
            photo={imovel.one_photo}
            bed={imovel.parking_spots}
            garage={imovel.dorms_number}
            address={imovel.address_properties}
          />
        ) : null
      );
    }
    return cityList.map((city, index) => (
      <LocationPin
        key={index}
        lat={parseFloat(city.latitude)}
        lng={parseFloat(city.longitude)}
        text={results.filter(result => result.address_properties?.city === city.city).length || 0}
      />
    ));
  };

  return (
    results.length > 0 && latCenter !== 0 && (
      <div className="map">
        <div className="google-map all">
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_TOKEN }}
            defaultCenter={{ lat: latCenter, lng: lngCenter }}
            defaultZoom={13}
            onZoomAnimationStart={event => setZoomVariation(event)}
          >
            {renderPins()}
          </GoogleMapReact>
        </div>
      </div>
    )
  );
}

export default AllMaps;
